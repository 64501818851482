* {
  scrollbar-width: thin;
  scrollbar-color: #1b1b1b transparent;
}

::-webkit-scrollbar {
  width: 9px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #1b1b1b;
}

/* Handle */

::-webkit-scrollbar-thumb {
  /* background: rgb(64 52 73); */
  background: rgb(54 54 54);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgb(156, 156, 156);
}

/* scrollbar styling */

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-10px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translatey(10px);
  }

  100% {
    opacity: 1;
    transform: translatey(0px);
  }
}

@keyframes move_up_down {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-150px);
  }

  100% {
    transform: translatey(0px);
  }
}

.loading-bouncing {
  background-color: #9c27b0;
  width: 3rem;
  height: 3rem;
  padding: 0.7rem;
  position: absolute;
  margin: 0 auto;
  top: 0;
  border-radius: 50%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  animation: bouncing 1s ease-in-out infinite;
}

@keyframes bouncing {
  0% {
    top: 10%;
    transform: rotate(0deg);
  }

  30% {
    top: 80%;
  }

  60% {
    top: 0%;
  }

  90% {
    top: 10%;
  }

  100% {
    transform: rotate(360deg);
    top: 10%;
  }
}

.modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 6rem;
  background-color: rgb(11 11 11 / 63%);
  z-index: 9999;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  display: none;
  overflow: auto;
}

body.modal_visible {
  overflow: hidden;
}

body.modal_visible .modal_wrap {
  display: flex;
}

.modal_content {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal_content .preview_image {
  /* width: 92%; */
  width: 75%;
  height: auto;
  /* max-height: 100%; */
  border-radius: 10px;
  border: 1px solid #210738;
}

.modal_content .close_modal {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  height: 42px;
  width: 42px;
  border-radius: 40px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.1s ease;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content .close_modal:hover {
  transform: rotate(90deg);
}

.modal_content .close_modal img {
  width: 16px;
}

.owl-theme .owl-nav {
  position: absolute;
  top: 0;
  width: 100%;
}

.owl-theme .owl-nav button {
  position: absolute;
}

.owl-theme .owl-nav .disabled {
  display: none
}

.owl-theme .owl-nav button:first-child {
  left: -4rem;
}

.owl-theme .owl-nav button:last-child {
  right: -4rem;
}

.owl-theme .owl-nav button span {
  font-size: 89px;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  margin-top: 1.25rem;
}

@media only screen and (min-width: 820px) and (max-width: 1400px) {
  .owl-theme .owl-nav button:first-child {
    left: -2.5rem;
  }

  .owl-theme .owl-nav button:last-child {
    right: -2.5rem;
  }

  .owl-theme .owl-nav button span {
    font-size: 64px;
  }
}

.container_limited {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1600px;
}

/* @media (min-width: 1921px) {
  .container_limited {
    padding-right: 0;
    padding-left: 0;
  }
} */