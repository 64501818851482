.section_wrap .section_wrap_review_wrap {
  flex-direction: row;
  align-items: flex-start;
}

.section_layout.section_layout_review_wrap {
  align-items: center;
}

.content_wrap_reviews_wrap {
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.reviews_content h3 {
  font-size: 34px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 0.9;
}

.reviews_wrap_container {
  /* width: calc(500px + 50px); */
  max-width: 100%;
  /* border-radius: 8px; */
  position: relative;
}

.reviews_wrap_container .bg_vector {
  z-index: 2;
}

.reviews_wrap_container .reviews_wrap_bg_mask {
  position: absolute;
  width: 350px;
  height: 100%;
  right: -100px;
  background-color: #2c0a43;
  z-index: 1;
}

.reviews_wrap_container .bg_vector_grid.left {
  left: -189px;
  display: none;
}

.reviews_wrap_container .bg_vector_grid.right {
  bottom: 35px;
  top: unset;
  display: none;
}

.reviews_wrap {
  /* height: 350px; */
  /* width: 500px; */
  width: 600px;
  max-width: 100%;
  overflow: hidden;
  /* margin-top: 4%; */
  color: inherit;
  /* background-color: #ffffff; */
  /* background-color: var(--bg-color-testimonial-card-wrapper); */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  /* border-radius: 8px; */
  position: relative;
  margin: 25px 0 60px 0;
  z-index: 3;
}

.reviews_wrap .review_list {
  display: flex;
  transition: all 400ms ease;
  width: 500%;
}

.reviews_wrap .review_list_actions {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  /* background-color: var(--bg-color-testimonial-actions-wrapper); */
  background-color: var(--bg-btn);
  border-radius: 8px 0 0 0;
}

.review_list_actions button {
  border: none;
  /* background-color: var(--bg-color-testimonial-actions-wrapper); */
  background-color: #13141c;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review_list_actions button:hover {
  background-color: #575757;
}

.review_list_actions button:first-of-type {
  transform: rotate(-180deg);
}

.review_list_actions button:last-of-type {
  transform: rotate(0deg);
}

.reviews_wrap .review_list_actions img {
  width: 11px;
  transform: rotate(-90deg);
  margin-right: -2px;
}

.reviews_wrap .review_item {
  /* width: 500px; */
  width: 600px;
  /* padding: 45px; */
  box-sizing: border-box;
  color: var(--color-testimonial-card);
}

.review_item .review_head {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.review_item .review_text {
  font-weight: 400;
  /* font-size: 22px; */
  font-size: 1rem;
  /* height: 225px; */
  /* height: 150px; */
  opacity: 0.8;
}

.review_item .review_author {
  margin-top: 1.3rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.review_author .author_avatar {
  width: 54px;
  height: 54px;
  margin-right: 15px;
  border-radius: 32px;
  background-color: rgb(175, 175, 175);
}

.review_author .author_avatar img {
  width: 100%;
  height: auto;
  border-radius: 30px;
}

.review_author .author_content {}

.review_author .author_name {
  font-size: 18px;
  font-weight: 500;
}

.review_author .author_desc {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.9;
}

.review_author .author_desc_alt {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.95;
}

.review_item {
  display: flex;
}

.review_author_logo {
  width: 225px;
  /* height: 100%; */
  /* background-color: green; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  overflow: hidden;
  display: none;
  padding: 0rem;
}

.review_content {
  padding: 0 0 0 2rem;
  flex: 1;
}

.review_author_logo img {
  width: 100%;
  /* height: 100%; */
}

/* .reviews_wrap img {
  animation-name: move_up_down;
  animation-duration: 20s;
  animation-iteration-count: infinite;
} */
/* For portrait - phone view (lower) */
@media (max-width: 600px) and (orientation: portrait) {
  .reviews_wrap {
    max-width: 90%;
    margin: 0 auto;
    border-radius: 6px !important;
  }

  .reviews_wrap_container .reviews_wrap_bg_mask {
    display: none;
  }

  .reviews_wrap .review_item {
    /* width: calc(100vw - 40px) !important; */
    /* width: 100vw !important; */
    width: 90vw !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    border-radius: 8px;
  }

  .review_author_logo {
    width: 100%;
    padding: 1rem;
  }

  .reviews_wrap .review_item {
    flex-direction: column;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .review_author_logo img {
    width: 9rem;
  }

  .review_content {
    padding: 1rem;
  }

  .review_item .review_text {
    font-size: 1.2rem;
    text-align: center;
  }
}

.review-badge {}

.review-badge img {
  width: 240px;
}