.section_wrap_cta_wrap {
  /* background-color: #1d052c; */
  /* background-color: #15151e; */
  background-color: var(--bg-color-footer);
  min-height: unset !important;
  height: auto !important;
  /* border-top: 4px solid #359e1d; */
}

.section_layout_cta_wrap {
  margin: 0;
  flex-direction: row !important;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0 !important;
}

.section_layout_cta_wrap .cta_graphic_img {
  height: 270px;
}

.section_layout_cta_wrap .titles_wrap_wrap {
  display: flex;
  flex: 1;
  justify-content: center;
}

.section_layout_cta_wrap .titles_wrap {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0;
  margin-top: 0;
  border-top: 0;
  padding: 1rem 4rem;
}

.section_layout_cta_wrap .titles_wrap .desc {
  margin-top: 1rem;
  font-size: 1.1em;
  font-weight: 400;
  width: auto;
}

.section_layout_cta_wrap .cta_wrap .cta_input {
  background-color: #fff;
  color: black;
}


@media (max-width: 600px) and (orientation: portrait) {
  .section_wrap .section_layout.section_layout_cta_wrap {
    padding-top: 0 !important;
  }
  .section_layout_cta_wrap .cta_graphic_img {
    display: none;
  }
  .section_layout_cta_wrap .titles_wrap {
    width: 100%;
    padding: 0 2rem;
    align-items: center;
  }
}