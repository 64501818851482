@import '../vendor/bootstrap.min.css';
/* @import './promos.css'; */
/* @import './promo_banners.css'; */

* {
  -webkit-font-smoothing: antialiased;
  -moz-os-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
body {
  /* font-family: sans-serif; */
  padding: 0;
  margin: 0;
  height: 100%;
  opacity: 1 !important;
  font-size: 16px;
}

.navbar {
  /* height: unset !important;
  overflow: visible; */
  display: unset !important;
}

body {
  /* font-family: var(--font-heading); */
  font-family: var(--font-feat-heading);
  padding: 0;
  margin: 0;
  /* background-color: #f7f7f7; */
  background-color: var(--bg-color-app);
  color: var(--color-app-primary);
}

a {
  color: #8799ff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-size: 1em;
  /* font-weight: 500; */
}

.padding-0 {
  padding: 0 !important;
}

.wrapper {
  width: 100%;
  /* height: 100%; */
  height: inherit;
  padding: 0;
  margin: 0 !important;
  overflow-x: hidden;
  background-color: var(--bg-color-app);
}

.hero-sections-container {
  height: inherit;
}

button:focus {
  outline: none !important;
}

.visible,
.invisible {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.visible {
  opacity: 1;
}

.cookie-box {
  position: fixed;
  bottom: le;
  bottom: 21px;
  left: 21px;
  width: 360px;
  z-index: 9;
  background-color: #f7f7f7;
  color: black;
  border-radius: 4px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 45%);
  transition: all 0.6s ease;
  opacity: 0;
}

.cookie-box.visible {
  opacity: 1;
}

.cookie-box .message-wrap {
  padding: 1rem;
  border-bottom: 1px solid #dfdfdf;
}

.cookie-box .message-wrap h2 {
  /* font-size: 19px; */
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 13px;
}

.cookie-box .message-wrap p {
  margin: 7px 0;
  margin-bottom: 0;
  /* font-size: 14px; */
  font-size: 0.86rem;
  opacity: 0.9;
}

.cookie-box .message-wrap p a {
  color: black;
  font-weight: 500;
  text-decoration: underline;
}

.cookie-box .actions-wrap {
  padding: 1rem;
}

.cookie-box .actions-wrap button {
  height: 35px;
  width: 100%;
  border-radius: 4px;
  /* background-color: #421e86; */
  /* background-color: #491e9b; */
  background-color: #435bdf;
  color: #fff;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.cookie-box .actions-wrap button:hover {
  background-color: #311e86;
}


/* For Landscopa - Laptop view (medium) */
@media only screen and (min-width: 820px) and (max-width: 1460px) {

  html,
  body {
    font-size: 15px;
  }
}

@media (max-width: 600px) and (orientation: portrait) {

  html,
  body {
    font-size: 14px;
  }

  .cookie-box {
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 2px;
  }

  .cookie-box .message-wrap h2+p {
    display: none;
  }
}