/*
* SECTION : FAQ
*/
#faqs.section_wrap {
  background-color: var(--bg-color-footer);
}

.content_wrap.content_wrap_faq_wrap {
  overflow-y: auto;
  margin: 4% 0 0 0;
  flex: none;
  display: flex;
  justify-content: flex-start;

  flex-direction: row;
  flex-wrap: unset;
  align-items: flex-start;
}

.titles_wrap_faq_wrap.titles_wrap {
  text-align: center;
}

.titles_wrap_faq_wrap .desc {
  width: auto !important;
  text-align: center !important;
}

.titles_wrap_faq_wrap a {
  color: white;
  text-decoration: underline;
  font-weight: 500;
}

.content_wrap.content_wrap_faq_wrap .faq_block {
  color: var(--color-app-primary);
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #232535;

  display: none;
}

.content_wrap.content_wrap_faq_wrap .faq_block.visible {
  display: block;
}

.content_wrap.content_wrap_faq_wrap .faq_block.visible:last-of-type {
  border-bottom-color: transparent;
}

.faq_block.collapsed {
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.faq_block .faq_head {
  /* border-bottom: 1px solid var(--border-color-faq-head); */
  display: flex;
  align-content: center;
  padding: 0.75rem 0;
  color: #fff;
  /* cursor: pointer; */
  /* font-size: 1rem; */
  font-size: 1.15rem;
  font-weight: 500;
}

.faq_head::first-letter {
  text-transform: uppercase;
}

.faq_block.collapsed .faq_head {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
}

.faq_block .faq_head .faq_head_toggle {
  width: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.faq_block .faq_head .faq_head_toggle img {
  width: 14px;
  display: none;
}

.faq_block.collapsed .faq_head .faq_head_toggle img {
  transform: rotate(180deg);
}

.faq_block .faq_body {
  padding: 0;
  transition: color 0.1s linear;
  font-weight: 300;
  font-size: 0.94rem;
  color: rgb(202, 202, 202);
  /* opacity: 0.7; */
}

.faq_body p {
  margin-bottom: 1rem;
}

.faq_body ol {
  /* padding: 0; */
  padding-left: 2rem;
  margin-top: 0.66rem;
  margin-bottom: 0;
  clear: both;
}

.faq_body ol li {
  margin: 0;
  margin-bottom: 0.33rem;
  padding: 0;
}

.faq_body ol li:last-of-type {
  margin-bottom: 0;
}

.faq_body p:last-of-type {
  margin-bottom: 0;
}

.faq_block:hover .faq_body {
  color: #fff;
}

.faq_body .highlight {
  color: #fff;
  margin-top: 0.25rem;
}

.faq_body .platform-names {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: none;
  font-weight: 400;
}

.platform-names .platform {
  width: 200px;
  margin-bottom: 0.2rem;
  font-size: 0.92rem;
}

.faq_body .encoder-names .platform {
  width: 225px;
}

.faq_menu_wrap {
  width: 24%;
}

.faq_list {
  flex: 1;
  max-width: 60%;
}

.faq_menu_wrap ul.faq_menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 1rem;
  box-sizing: border-box;
  /* padding-top: 31%; */
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li.faq_menu_item {
  margin-bottom: 1rem;
  font-size: 1.08rem;
  font-weight: 500;
  opacity: 0.5;
  padding-bottom: 0.1rem;
  border-bottom: 2.5px solid transparent;
  transition: all 0.15s ease;
  cursor: pointer;
}

li.faq_menu_item:hover,
li.faq_menu_item.active {
  opacity: 1;
  border-bottom-color: currentColor;
}

/*
* SECTION : FAQ -- ends
*/

/* For Landscopa - Laptop view (medium) */
@media only screen and (min-width: 820px) and (max-width: 1400px) {
  .faq_block .faq_head {
    /* padding: 20px 30px; */
  }

  .content_wrap.content_wrap_faq_wrap .faq_block {
    /* width: 350px; */
  }

  .content_wrap.content_wrap_faq_wrap {
    /* height: 700px; */
  }
}

@media (min-width: 1100px) and (max-width: 1440px) {
  .faq_menu_wrap {
    width: 27%;
  }

  .faq_list {
    max-width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 1100px) {
  .faq_menu_wrap {
    width: 30%;
  }

  .faq_list {
    max-width: 90%;
  }

  li.faq_menu_item {
    font-size: 1rem;
  }
}

@media (min-width: 601px) and (max-width: 850px) {
  .faq_menu {
    padding-left: 0 !important;
  }

  .faq_menu_item {
    font-size: 0.85rem;
  }

  .faq_list {
    max-width: unset;
  }
}


/* For portrait - phone view (lower) */
@media (max-width: 600px) and (orientation: portrait) {

  .titles_wrap_faq_wrap.titles_wrap,
  .section_wrap .titles_wrap_faq_wrap.titles_wrap .desc {
    text-align: left !important;
  }

  .content_wrap.content_wrap_faq_wrap .faq_block {
    margin-right: 0;
    margin-bottom: 0;
  }

  .faq_head .faq_head_txt,
  .faq_block .faq_body {
    font-size: 0.9rem !important;
    line-height: 1.5;
  }

  ul.faq_menu {
    flex-direction: row !important;
    width: 1200px;
    width: max-content;
    /* flex-wrap: wrap; */
    padding: 0 !important;
  }

  .faq_menu_wrap {
    width: 100%;
    overflow-x: auto;
  }

  li.faq_menu_item {
    margin-right: 1.5rem;
    font-size: 1rem;
  }

  .faq_list {
    max-width: unset;
    margin-top: 1rem;
  }

  .platform-names .platform {
    width: 50%;
  }
}