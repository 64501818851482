@import '/assets/css/theme.css';
@import '/assets/css/plugins.css';
@import '/assets/css/shared/layout.css';
@import '/assets/css/shared/navbar.css';
@import '/assets/css/shared/footer.css';
@import '/assets/css/shared/feature_list.css';
@import '/assets/css/shared/feature_showcase.css';
@import '/assets/css/shared/layout_landing.css';
@import '/assets/css/shared/landing_pricing.css';
@import '/assets/css/shared/landing_pricing_cdn.css';
@import '/assets/css/shared/landing_faq.css';
@import '/assets/css/shared/landing_testimonial.css';
@import '/assets/css/shared/destinations_list.css';
@import '/assets/css/shared/landing_cta.css';
@import './responsive.css';

/* 3. HEADER */

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  /* padding: 60px 0 0 0; */
  justify-content: space-evenly;
  align-items: center;
  /* border-bottom: 1px solid var(--border-color-prod-compare-wrap); */
  padding-top: 9rem;

  /* background-image: url(/assets/images/bg-header-gradient.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% auto; */
  /* background-color: #181d28; */
}

.header.fold1 {
  /* background: var(--bg-layout); */
}

.header-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.header-title-section {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header-img-section {
  overflow: visible;
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 6rem;

  /* margin-top: 6rem;
  padding: 1.5rem;
  padding-bottom: 0; */
}

/* .header-img-section::before {    
  content: "";
  background-image: url(/assets/images/bg-header-pattern.jpg);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.8;
  border-radius: 8px;
} */

.header-img-section .multistream-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url(/assets/images/stream-to-multiple-sites_home_hero.png);
  background-size: 460px;
  background-repeat: no-repeat;
  background-position: left 19%;
  margin-left: -19%;
  display: none;
}

.header-img-section img {
  /* max-width: 100%; */
  margin-bottom: -40px;
  position: static;
  z-index: 2;
  /* max-height: 600px; */
  /* width: 900px; */
  /* width: 78%; */
  width: 100%;
  height: auto;
  z-index: 1;
}

.promo-img-addon-left {
  height: 450px !important;
  width: unset !important;
  margin-right: 30px;
}

.promo-img-addon-right {
  height: 450px !important;
  width: unset !important;
  margin-left: -15px;
}

/* .header-img-section img:hover {
  margin-bottom: unset;
} */

.header-title {
  font-family: var(--font-heading-alt);
  font-size: 3.8rem;
  font-weight: 600;
  /* line-height: 63px; */
  padding-bottom: 18px;
  color: var(--color-app-primary);
  /* letter-spacing: -1px; */
  /* width: 720px; */
  text-align: center;
  position: relative;
  /* display: flex;
  align-items: flex-start; */
}

.header-sprite {
  position: absolute;
  width: 40%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.header-sprite.sprite-left {
  left: 0;
  top: 0;
  /* background-image: url(../../assets/images/sprites/home-features-hand-written.png); */
  background-image: url(../../assets/images/sprites/home-features-hand-written.png);
  background-position: top left;
  margin-left: -3rem;
}

.header-sprite.sprite-right {
  right: 0;
  top: 0;
  background-image: url(../../assets/images/sprites/home-features-hand-written-II.png);
  background-position: top right;
  height: 250px;
  margin-right: -3rem;
}

.header-title .multiply-icon {
  /* position: absolute;
  top: -5px; */
  width: 42px;
  margin-left: -5px;
  margin-top: -3rem;
}

.header-title .hero-content {
  position: relative;
  display: inline-block;
}

.header-title .hero-content::before {
  display: none;
  content: '';
  background-color: #aaaaff;
  opacity: 0.6;
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 4px;
}

.header-title.sub-hero-content {
  /* font-size: 2.4rem; */
  /* font-size: 30px; */
  font-family: var(--font-feat-heading);
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 42px;
  opacity: 0.85;
  /* width: 720px; */
  text-align: center;
}

.highlight,
.header-title-section .highlight,
.header-title .highlight {
  /* color: var(--color-app-highlight); */
  /* border-bottom: 4px dashed var(--color-app-highlight); */
  border-bottom: 4px dashed rgb(155, 111, 255);
  font-weight: 600;
}

.header-title-text {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
  padding-bottom: 15px;
  color: var(--color-app-primary);
  opacity: 0.9;
}

.header-title-section .sub-hero-content-alt {
  line-height: 1;
  padding-bottom: 25px;
  color: var(--color-app-primary);
  font-size: 23.5px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.header-title-section .learn-more-btn-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.hero-section {
  padding: 7% 0;
}

.hero-section .hero-img-section {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}

.hero-section .header-img-section img {
  margin-top: 0;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 57%),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media screen and (max-width: 991.98px) {
  .header-container {
    flex-direction: column;
  }

  .header-title-section {
    text-align: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 799.98px) {
  .header {
    height: auto;
    padding-top: 20%;
  }
}

@media screen and (max-width: 499.98px) {
  .header {
    padding-top: 30%;
  }

  .header-subtitle {
    font-size: 24px;
  }

  .header-title {
    font-size: 46px;
  }

  .header-title-text {
    font-size: 18px;
  }
}

.section_wrap.section_wrap_prod_compare {
  /* padding: 0; */
  /* background: var(--bg-color-prod-item-box-wrap); */
  /* background: url(../images/vectors/spiral-bg.webp); */
  /* background-size: 120% auto;
  background-position: 0 -10px; */
  padding-top: 0;
  display: none;
}

.section_wrap_prod_compare .section_layout {
  padding-bottom: 0;
  padding: 0;
}

.section_wrap_prod_compare_titles .titles_list_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.section_wrap_prod_compare_titles .titles_list_wrap .graphics_wrap {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.15;
}

.section_wrap_prod_compare_titles .titles_list_wrap .titles_wrap {
  flex: 1;
  max-width: 475px;
}

.section_wrap_prod_compare_titles .titles_list_wrap .titles_wrap .title_main {
  font-size: 2rem;
  letter-spacing: -0.4px;
}

.content_wrap.content_wrap_prod_compare_wrap {
  /* margin-top: 4%; */
  /* border-top: 1px solid var(--border-color-prod-compare-wrap); */
  align-items: flex-start;
  margin-top: 0;
  padding: 0;

  /* align-items: flex-end;
  flex-direction: column;
  justify-content: center; */
}

.content_wrap .prod_compare_item {
  height: 100%;
  width: 100%;
  /* padding: 0 2rem; */
  /* flex: 1; */
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 475px;
  /* max-width: 35%; */
}

.prod_compare_item:first-of-type {
  justify-content: flex-end;
  margin-right: 6rem;
}

.prod_compare_item .preview_box {
  /* margin-top: 88px; */
  width: 540;
  height: 310px;
  background-size: auto;
  background-position: top left;
  background-repeat: no-repeat;
  border-radius: 6px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.prod_compare_item .preview_box .preview_img {
  /* height: 100%; */
  height: auto;
  width: 650px;
}

/* .prod_compare_item .preview_box:hover + .preview_box_mask { */
.prod_compare_item .preview_box.variant1 {
  /* background-image: url(/assets/images/dash_screens/multistreaming-alt-3.jpg);
  background-position: top left;
  background-size: 140% auto; */
}

.preview_box.variant2 {
  /* background-image: url(/assets/images/dash_screens/scheduling.jpg);
  background-position: top right;
  background-size: auto 119%; */
}

.prod_compare_item:hover .preview_box_mask {
  background-color: rgb(11 11 11 / 57%);
}

.prod_compare_item .preview_box_mask {
  position: absolute;
  top: 0;
  /* width: 640px;
  height: 400px; */
  width: 100%;
  height: 310px;
  background-color: transparent;
  z-index: 3;
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview_box_mask.variant1 {
  /* right: 2rem; */
}

.preview_box_mask.variant2 {
  /* left: 2rem; */
}

.preview_box_mask button {
  border: none;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  visibility: hidden;
}

.preview_box_mask button img {
  width: 32px;
}

.prod_compare_item:hover .preview_box_mask button {
  visibility: visible;
}

/* .prod_compare_item:hover .prod_compare_item_mask {
  background-color: var(--bg-color-prod-compare-item-hover-mask);
} */
/* .prod_compare_item .prod_compare_item_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-prod-compare-item-mask);
  pointer-events: none;
} */
.prod_compare_item .prod_compare_item_img {
  max-width: 250px;
  height: 200px;
  margin-bottom: 5%;
}

.prod_compare_item .prod_compare_item_img img {
  max-height: 100%;
}

.prod_compare_item .titles_wrap {
  max-width: 500px;
  z-index: 1;
}

.content_wrap .prod_compare_divider {
  width: 1px;
  height: 100%;
  background-color: var(--border-color-prod-compare-wrap);
}

/* service kb */
.section_wrap .titles_wrap_kb_wrap {
  /* background-image: url(/assets/images/keep_productive.svg);
  background-position: center right;
  background-size: 30% auto;
  background-repeat: no-repeat; */
}

.content_wrap.content_wrap_kb_wrap {
  margin-top: 6%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: visible;
}

.titles_wrap_kb_wrap .title_main,
.titles_wrap_kb_wrap .desc {
  max-width: 600px;
}

.content_wrap_kb_wrap .kb_feat {
  /* flex: 1; */
  height: 330px;
  /* border: 1px solid; */
  border-radius: 8px;
  background-color: #4b0082;
  padding: 2rem;
  width: 330px;
  margin-right: 2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.kb_feat .kb_feat_head {
  font-size: 1.3em;
  font-weight: 500;
  /* text-align: center; */
  /* border: 1px solid; */
  /* padding: 18px; */
  border-radius: 8px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: #f7e0ff;
  /* text-decoration: underline; */
}

.kb_feat .kb_feat_body {
  margin-top: 1rem;
  font-size: 18px;
  /* display: none; */
}

/* service kb -- ends */

.section_layout.section_layout_feature {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.section_layout_feature .titles_wrap,
.section_layout_feature .content_wrap {
  /* flex: 1 !important; */
  width: 500px;
  flex: unset;
  position: relative;
}

.section_layout_feature .titles_wrap .title_main {
  font-size: 2rem;
  line-height: 2.75rem;
  letter-spacing: -0.3px;
}

.content_wrap.content_wrap_feature_wrap {
  padding: 0 60px 0 0;
  position: relative;
  align-self: stretch;
  overflow: unset;
}

.content_wrap.content_wrap_feature_wrap_exp {
  width: unset !important;
  flex: 1;
  padding: 0 !important;
}

.content_wrap.content_wrap_feature_wrap.right {
  padding: 0 0 0 60px;
}

.snap_preview_wrap {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 3px 47px rgba(0, 0, 0, 0.15);
  background-repeat: no-repeat;
  /* border: 3px solid #e6bb19; */
}

.content_wrap.content_wrap_feature_wrap_exp .snap_preview_wrap {
  text-align: center;
}

.section_layout_feature .titles_wrap .desc {
  width: auto;
  /* font-size: 1.25rem; */
  text-align: left;
}

.snap_preview_wrap img {
  /* width: 440px; */
  width: 460px;
  height: auto;
  /* height: 450px; */
  z-index: 2;
  position: relative;
  /* margin-top: 20px; */
  margin-top: 10px;
  margin-left: 4px;
}

.snap_preview_wrap.snap_preview_wrap_2 img {
  margin-left: -40px;
  width: 550px;
}

.content_wrap_feature_wrap_exp .snap_preview_wrap img {
  /* margin-left: -40px; */
  width: 80%;
}

.snap_preview_wrap.snap_preview_wrap_3 img {
  margin-left: -40px;
  width: 520px;
}

.snap_preview_wrap .snap_preview_mask {
  /* width: 460px; */
  width: 280px;
  height: 270px;
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  background: rgb(131, 93, 255);
  background: linear-gradient(90deg,
      rgba(131, 93, 255, 1) 0%,
      rgba(123, 23, 236, 1) 100%);
}

.snap_preview_mask.snap_preview_mask_2 {
  display: none;
  width: 410px;
  height: 360px;
  background: rgb(170, 29, 228);
  background: linear-gradient(90deg,
      rgba(170, 29, 228, 1) 0%,
      rgba(216, 0, 255, 1) 100%);
}

.snap_preview_mask.snap_preview_mask_3 {
  display: none;
  height: 290px;
}

/* .section_layout_feature .vector_ball { */
.vector_ball {
  background-image: url(/assets/images/vectors/ball_sm.webp);
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: absolute;
}

/* .section_layout_feature .titles_wrap .vector_ball { */
.titles_wrap .vector_ball {
  width: 60px;
  height: 60px;
  top: -100px;
  right: 0;
}

/* .section_layout_feature .content_wrap .vector_ball { */
.content_wrap .vector_ball {
  top: -20px;
  right: -60px;
}

.vector_ball.vector_ball_sm {
  width: 40px !important;
  height: 40px !important;
}

.multistream_guide {}

/* For Landscopa - Laptop view (medium) */
@media only screen and (min-width: 820px) and (max-width: 1460px) {
  .header {
    padding-top: 6rem;
  }

  .header-title {
    font-size: 3.5rem;
    padding-bottom: 10px;
    width: unset;
  }

  .header-title.sub-hero-content {
    font-size: 1.15rem;
    width: unset;
    letter-spacing: 0;
  }

  .header-img-section {
    padding-top: 4rem;
  }

  .header-img-section img {
    /* width: 60% !important; */
    width: 90% !important;
  }

  .header-img-section .promo-img-addon-left {
    height: 370px !important;
    width: unset !important;
    /* margin-right: -25px; */
  }

  .header-img-section .promo-img-addon-right {
    height: 350px !important;
    width: unset !important;
    margin-left: -10px;
  }

  .section_wrap_prod_compare_titles .titles_list_wrap .titles_wrap .title_main {
    font-size: 2.4rem;
  }

  .section_layout_feature .titles_wrap .title_main {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .snap_preview_wrap .snap_preview_mask {
    width: 240px;
    /* height: 360px; */
  }

  .snap_preview_wrap img {
    width: 400px !important;
    margin-left: 0 !important;
  }

  .content_wrap_feature_wrap_exp .snap_preview_wrap img {
    width: 80% !important;
  }
}

/* For portrait - phone view (lower) */
@media (max-width: 600px) and (orientation: portrait) {
  .header-title .multiply-icon {
    width: 32px;
    margin-top: -2rem;
  }

  .section_wrap.section_wrap_quick_cta {
    margin: 0 !important;
    padding: 0 !important;
  }

  .section_wrap_prod_compare_titles {
    /* display: none; */
  }

  .section_wrap_prod_compare_titles .titles_list_wrap .titles_wrap:last-of-type {
    margin-top: 2rem;
  }

  .snap_preview_wrap.snap_preview_wrap_2 img,
  .snap_preview_wrap.snap_preview_wrap_3 img {
    margin-left: 0;
  }

  .quick_cta {
    text-align: center;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }

  .quick_cta h3 {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .highlight,
  .header-title-section .highlight,
  .header-title .highlight {
    font-weight: inherit;
    border-bottom: none;
  }

  .snap_preview_wrap {
    margin: 0 auto;
    margin-bottom: 1.5rem;
    max-width: 90%;
  }

  .snap_preview_wrap.snap_preview_wrap_2 {
    margin-left: 1.6rem;
  }

  .header-sprite.sprite-left {
    height: 156px;
  }

  .header-sprite.sprite-right {
    right: 0;
    top: 0;
    background-position: 0 19%;
    height: 180px;
    background-position-y: -6rem;
    background-size: auto 100%;
    width: 60%;
  }
}